import {
    Container, Heading, Center, Card, Text, Box, SimpleGrid, CardHeader, CardBody
} from '@chakra-ui/react'
import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DataResume from '../../data/DataResume.json';
import { IconCheck } from '../../styles/icons';

const Resume = () => {

    const main = useRef(null);
    useLayoutEffect(() => {
        const mm = gsap.matchMedia();

        mm.add("(min-width: 1080px)", () => {
            const tl = gsap.timeline()
                .from(".resume h2", { y: 120, autoAlpha: 0 }, 0)
                .from(".resume .chakra-card", { y: 120, autoAlpha: 0, stagger: 0.1 }, 0)
            ScrollTrigger.create({
                trigger: main.current,
                animation: tl,
                toggleActions: "play none none reverse",
                start: "top center",
                end: "bottom bottom",
            })
        }, main);
        return () => mm.revert();
    }, []);

    return (
        <>
            <Box className='resume' background='gradientLighter' ref={main} pt={[16, 16, 16, '160px']} pb={[16, 16, 16, '180px']}>
                <Container position='relative' maxW='container.xl'>
                    <Center flexDirection='column' mb='60px'>
                        <Heading as='h2' color='white' size='2xl' textAlign={['center']}>What our clients will receive</Heading>
                    </Center>
                    <SimpleGrid spacing={6} columns={[1, 1, 2, 3, 3]}>
                        {DataResume.map((item, i) => (
                            <Card key={i} boxShadow='none'>
                                <CardHeader textAlign='center'>
                                    <IconCheck height='40px' width='40px' color='primary' />
                                    <Heading color='title' size='md' >{item.title}</Heading>
                                </CardHeader>
                                <CardBody textAlign='center'>
                                    <Text color='text'>{item.text}</Text>
                                </CardBody>
                            </Card>
                        ))}
                    </SimpleGrid>


                </Container>
            </Box>
        </>
    )
};

export default Resume;