import HomeIntro from "./HomeIntro";
import About from "./HomeAbout";
import Services from "./HomeServices";
import Tech from "./HomeTech";
import Cases from "./HomeCases";
import Resume from "./HomeResume";
import Contact from "./HomeContact";
import { useEffect } from 'react';


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <HomeIntro />
            <About />
            <Services />
            <Tech />
            <Cases />
            <Resume />
            <Contact />
        </>
    )
};

export default Home;