import { extendTheme } from '@chakra-ui/react';
import { cardTheme } from './card';
import { tagTheme } from './tag';
import { formTheme } from './form';
import { tabsTheme } from './tab';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}
const Button = {
    // The styles all button have in common
    baseStyle: {
        borderRadius: '16',
    },
    variants: {
        solid: {
            bg: 'none',
            color: 'white',
            px: '5',
            _hover: {
                bg: 'whiteAlpha.300'
            }
        },
        accent: {
            bg: 'accent',
            color: 'blackAlpha.900',
            px: '8',
            _hover: {
                bg: '#00DCF4'
            }
        },
        animate: {
            color: 'blackAlpha.900',
            px: '8',
            _hover: {
                // bg:'#00DCF4'
            }
        },
        outline: {
            border: '2px solid',
            borderColor: 'white',
            color: 'white',
            px: '8',
            _hover: {
                bg: 'whiteAlpha.300'
            }
        },
        ocean: {
            color: 'white',
            background: 'gradientOcean',
            px: '8'
        },
        invisible: {
            bg: 'none',
            color: 'white',
            px: '5',
            _hover: {
                bg: 'none',
                color: 'accent'
            }
        },
    },
}
const theme = extendTheme({
    config,
    components: {
        Button,
        Card: cardTheme,
        Tag: tagTheme,
        Form: formTheme,
        Tabs: tabsTheme,
    },
    colors: {
        gradientPrimary: 'linear-gradient(180deg, #0075FF 0%, #75B4FF 100%)',
        gradientDark: 'linear-gradient(180deg, #3E96FF 0%, #0075FF 100%)',
        gradientDarkWave: 'linear-gradient(180deg, #0075FF 0%, #3E96FF 100%)',
        gradientOcean: 'linear-gradient(90deg, #0075FF 0%, #00D1FF 100%)',
        gradientOceanWave: 'linear-gradient(180deg, #0075FF 0%, #00D1FF 100%)',
        gradientLighter: 'linear-gradient(180deg,#3E96FF 0%, #ebf4ff 100%)',

        primary: '#3E96FF',
        accent: '#00FFF0',
        light: '#9AC8FF',
        lighten: '#DAEBFF',
        lighter: '#ebf4ff',
        background: '#F8FBFF',

        white: '#FFFFFF',
        dark: '#0075FF',

        title: '#474747',
        text: '#7383A1'
    },
    styles: {
        global: {
            body: {
                bg: 'background',
            },
            p: {
                lineHeight: '28px',
            }
        }
    },
    textStyles: {
        platinum: {
            color: 'red',
            fontSize: 'lg',
            lineHeight: '32px'
        },
    },
    fonts: {
        heading: `'Source Sans Pro', sans-serif`,
        body: `'Source Sans Pro', sans-serif`,
    },
});

export default theme;