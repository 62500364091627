import { Container, SimpleGrid, Heading, Text, Image, Card, CardBody, CardHeader, Box } from '@chakra-ui/react'
import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DataServices from '../../data/DataServices.json';

const Services = () => {

    const main = useRef(null);
    useLayoutEffect(() => {
        const mm = gsap.matchMedia();

        mm.add("(min-width: 1080px)", () => {
            const tl = gsap.timeline()
                .to(".HomeServices .services-blob", { y: 40 }, 0)
                .from(".HomeServices h2", { y: 120, autoAlpha: 0 }, 0)
                .from(".HomeServices p", { y: 150, autoAlpha: 0 }, 0)
                .from(".HomeServices .chakra-card", { y: 150, autoAlpha: 0, stagger: 0.2 }, 0)
            ScrollTrigger.create({
                trigger: main.current,
                animation: tl,
                scrub: 2,
                start: "top 50%",
                end: "bottom bottom",
            });
        });

        return () => mm.revert();
    }, []);

    return (
        <>
            <Box className='HomeServices' ref={main} position='relative' py={[16, 16, 16, '120px']}>
                <Box className='services-bg' background='gradientPrimary' />
                <Box className='services-blob' background='lighten' />
                <Container maxW='container.xl'>
                    <Heading color='white' as='h2' size='2xl' mb='4' textAlign={['center', 'center', 'center', 'left']}>Our Services</Heading>
                    <Text color='white' mx={['auto', 'auto', 'auto', '0']} mb={[16, 16, 16, 8]} maxW={['100%', 400, 400]} textStyle='platinum' textAlign={['center', 'center', 'center', 'left']}>
                        We transform your business with our development solutions and maintenance services.
                    </Text>
                    <SimpleGrid mt='-20px' spacing={6} columns={[1, 2, 2, 3, 3]}>
                        <Box gridRowEnd='span 4' display={['none', 'none', 'none', 'none', 'block']} />
                        <Box gridRowEnd='span 6' display={['none', 'none', 'none', 'none', 'block']}>
                            <Image borderRadius='24px' opacity='0.25' boxSize='100%' objectFit='cover' src={require('../../assets/services/design.jpg')} alt='Live Connect' />
                        </Box>
                        <Box gridRowEnd='span 4' display={['none', 'none', 'none', 'none', 'block']} />

                        {DataServices.map((item, i) => (
                            <Box key={i} gridRowEnd={'span ' + item.grid} >
                                <Card variant='funky' data-theme='light'>
                                    <CardHeader>
                                        <Image boxSize='100%' objectFit='cover' src={require('../../assets/services/' + item.title + '.jpg')} alt={'Live Connect ' + item.title} />
                                    </CardHeader>
                                    <CardBody padding={['24px', '32px', '32px', '48px']}>
                                        <Text>{item.text}</Text>
                                    </CardBody>
                                </Card>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    )
};

export default Services;