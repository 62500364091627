import { Container, SimpleGrid, Heading, Text, Center, Image, Box, Card, Flex, Divider, Tag, Tabs, Tab, TabList, TabPanel, TabPanels, Button } from '@chakra-ui/react'
import React, { useRef, useLayoutEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DataCases from '../../data/DataCases.json';
import { IconPrev, IconForw } from '../../styles/icons';

const Cases = () => {

    const main = useRef(null);
    useLayoutEffect(() => {
        const mm = gsap.matchMedia();

        mm.add("(min-width: 1080px)", () => {
            const tl = gsap.timeline()
                .from(".cases h2", { y: 120, autoAlpha: 0 }, 0)
                .from(".cases p", { y: 150, autoAlpha: 0 }, 0)
                .from(".cases .chakra-card", { y: 80, autoAlpha: 0, }, 0.8)
                .from(".cases .cases-blob", { y: 80, autoAlpha: 0, }, 0.4)
            ScrollTrigger.create({
                trigger: main.current,
                animation: tl,
                toggleActions: "play none none reverse",
                start: "top center",
                end: "bottom bottom",
            })
        }, main);
        return () => mm.revert();
    }, []);

    const tabNumber = Object.keys(DataCases).length - 1;
    const [tabIndex, setTabIndex] = React.useState(0)
    const handleTabsChange = (index) => {
        setTabIndex(index)
    }
    const tabNext = () => {
        if (tabIndex !== tabNumber) {
            setTabIndex(tabIndex + 1)
        } else {
            setTabIndex(0)
        }
    }
    const tabPrev = () => {
        if (tabIndex !== 0) {
            setTabIndex(tabIndex - 1)
        } else {
            setTabIndex(tabNumber)
        }
    }

    return (
        <>
            <Box className='cases' py={[16, 16, 16, '120px']} background='gradientDark' ref={main}>
                <Container maxW='container.md'>
                    <Center flexDirection='column' mb='32px'>
                        <Heading color='white' as='h2' size='2xl' mb='4'>Business Unit</Heading>
                        <Text color='white' textAlign='center' maxW={['100%', 400, 500]} mb='8' textStyle='platinum'>
                            Given years of experience providing solutions and implementing various system modules, we are well-positioned to offer:
                        </Text>
                    </Center>
                    <Flex flexDirection='row' alignItems='stretch' justifyContent='center' position='relative'>
                        <Box className='cases-blob' background='gradientDark' />
                        <Button variant='invisible' onClick={tabPrev} h='auto' px={[0, 0, 5]} display={['none', 'none', 'block', 'block', 'block']}>
                            <IconPrev height='40px' width='40px' />
                        </Button>

                        <Card boxShadow='none' width='100%' borderRadius='40' p={[1, 4, 4, 4, 4]} pb={[4]}>
                            <Tabs isLazy={true} variant='carousel' display='flex' gap={['4']} flexDirection='column' index={tabIndex} onChange={handleTabsChange}>
                                <TabPanels>
                                    {DataCases.map((item, i) => (
                                        <TabPanel p='0' key={i}>
                                            <SimpleGrid minChildWidth='120px' gap='16'>
                                                <Box px='8' pb='2' pt='6'>
                                                    <Flex>
                                                        <Heading mb='2' mr='2' color='primary' size='md' display={['block', 'block', 'none']} >{i + 1}.</Heading>
                                                        <Heading mb='2' color='title' size='md' >{item.title}</Heading>
                                                    </Flex>
                                                    <Text color='text'>{item.text}</Text>
                                                    <Tag mt='4'>{item.tag}</Tag>
                                                </Box>
                                            </SimpleGrid>
                                        </TabPanel>
                                    ))}
                                </TabPanels>
                                <Divider display={['none', 'none', 'block', 'block', 'block']} />
                                <TabList gap='2' mx='auto' display={['none', 'none', 'flex', 'flex', 'flex']}>
                                    {DataCases.map((item, i) => (
                                        <Tab key={i} textAlign='left'>{i + 1}</Tab>
                                    ))}
                                </TabList>

                            </Tabs>
                        </Card>

                        <Button variant='invisible' onClick={tabNext} h='auto' px={[0, 0, 5]}>
                            <IconForw height='40px' width='40px' />
                        </Button>
                    </Flex>
                </Container>
            </Box >
        </>
    )
};

export default Cases;