import { Container, Heading, Box } from '@chakra-ui/react'
import ContactForm from '../../components/ContactForm';

const Contact = () => {

    return (
        <>
            <Box background='lighter'>
                <Box className='contact' pt={[16, 16, 16, '160px']} pb={[16, 16, 16, '120px']} background='background'>
                    <Container maxW='container.md'>
                        <Heading as='h2' size='2xl' color='primary' mb='16' textAlign={['center', 'center', 'center', 'left']}>Let’s connect!</Heading>
                        <ContactForm />
                    </Container>
                </Box>
            </Box>
        </>
    )

};

export default Contact;