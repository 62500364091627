import { formAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(formAnatomy.keys)

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)"
};

const variants = {
    floating: {
        container: {
            _hover: {
                input: {
                    borderColor: 'light'
                },
                textarea: {
                    borderColor: 'light'
                },
            },
            _focusWithin: {
                input: {
                    borderColor: 'primary'
                },
                textarea: {
                    borderColor: 'primary'
                },
                label: {
                    ...activeLabelStyles,
                    my: 2,
                    color: 'primary',
                }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                ...activeLabelStyles,
                my: 2,
            },
            input: {
                borderRadius: '16px',
                border: '2px solid',
                borderColor: 'lighten',
                height: '54px',
                color: 'title',
                fontSize: '18px'
            },
            textarea: {
                borderRadius: '16px',
                border: '2px solid',
                borderColor: 'lighten',
                height: '54px',
                color: 'title',
                fontSize: '18px',
                height: '150px'
            },
            label: {
                fontSize: '16px',
                fontWeight: '600',
                top: 0,
                left: 0,
                zIndex: 2,
                position: "absolute",
                color: "text",
                backgroundColor: "background",
                pointerEvents: "none",
                mx: 3.5,
                px: 1.5,
                my: 3.5,
                transformOrigin: "left top"
            }
        }
    }
}

export const formTheme = defineMultiStyleConfig({ variants })