import { createIcon } from '@chakra-ui/icons';

export const IconTeam = createIcon({
    displayName: 'IconTeam',
    d: "M10 0a6.667 6.667 0 1 0 0 13.333A6.667 6.667 0 0 0 10 0ZM6 6.667a4 4 0 1 1 8 0 4 4 0 0 1-8 0ZM20.544 6.958a2.667 2.667 0 0 0-1.21-.291V4a5.333 5.333 0 1 1-3.143 9.643l-.002-.002 1.572-2.154a2.666 2.666 0 1 0 2.783-4.53ZM24.664 24a5.331 5.331 0 0 0-5.33-5.33V16a8.001 8.001 0 0 1 8 8h-2.67ZM19.333 24h-2.666a6.667 6.667 0 0 0-13.334 0H.667A9.333 9.333 0 0 1 10 14.667 9.333 9.333 0 0 1 19.333 24Z"
})

export const IconGrowth = createIcon({
    d: "M14.333 11.885c.25.25.59.39.943.391h.781c.354 0 .693-.14.943-.39l6.276-6.277 3.057 3.058v-8h-8l3.058 3.057-5.724 5.724-4-4c-.25-.25-.59-.39-.943-.39h-.782c-.353 0-.692.14-.942.39L.057 14.391l1.885 1.885 8.391-8.39 4 4Z"
})

export const IconFlag = createIcon({
    d: "M1.333 23.333A1.333 1.333 0 0 1 0 22V2C0 1.264.597.667 1.333.667H9.84c.507-.004.972.28 1.2.733L12 3.333h6.667c.736 0 1.333.597 1.333 1.334v10.666c0 .737-.597 1.334-1.333 1.334H11.48a1.333 1.333 0 0 1-1.187-.734L9.333 14H2.667v8c0 .736-.597 1.333-1.334 1.333Zm1.334-20v8h8L12 14h5.333V6h-6.666L9.333 3.333H2.667Z"
})

export const IconSignal = createIcon({
    d: "M0 2.93C0 1.313 1.323 0 2.954 0c4.515 0 9.724 1.583 13.85 5.014 4.21 3.5 7.196 8.85 7.196 16.055C24 22.688 22.677 24 21.046 24c-1.631 0-2.693-1.205-2.693-2.823 0-5.435-2.444-9.261-5.342-11.67-2.981-2.479-6.949-3.86-10.187-3.86C1.192 5.647 0 4.55 0 2.931Z"
})

export const IconPrev = createIcon({
    d: "M15.41 7.41L10.83 12l4.58 4.59L14 18l-6-6 6-6z"
})

export const IconForw = createIcon({
    d: "M8.59 16.59L13.17 12l-4.58-4.59L10 6l6 6-6 6z"
})

export const IconCheck = createIcon({
    d: "M7.23228 13.3648C6.45123 14.1458 5.1849 14.1458 4.40385 13.3648L0.868102 9.829C0.0871712 9.04807 0.0871708 7.78193 0.868102 7.001V7.001C1.64981 6.21929 2.91749 6.22019 3.6981 7.003L5.81806 9.12897L14.3018 0.636684C15.0827 -0.145037 16.35 -0.144928 17.1313 0.6364V0.6364C17.9124 1.41745 17.9128 2.68421 17.1318 3.46525L7.23228 13.3648Z"
})

export const IconLinkedIn = createIcon({
    d: "M10 18H6V6H10V8C10.8526 6.91525 12.1456 6.26857 13.525 6.237C16.0056 6.25077 18.0072 8.26938 18 10.75V18H14V11.25C13.84 10.1326 12.8818 9.30358 11.753 9.306C11.2593 9.32161 10.7932 9.53778 10.4624 9.90462C10.1316 10.2715 9.96464 10.7573 10 11.25V18ZM4 18H0V6H4V18ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
})