import React, { useState } from "react";
import { Box, Container, Image, SimpleGrid, Heading, Divider, Text, Tag, Flex } from '@chakra-ui/react'
import { IconCheck } from '../styles/icons';

const Clip = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 4000);
            })
            .catch((err) => console.error("Error al copiar texto:", err));
    };

    return (
        <Flex alignItems='center' justifyContent='center'>
            <Text cursor='pointer' onClick={handleCopy}>
                {text}
            </Text>
            {copied &&
                <Tag ml='2' px='2'>
                    Copied
                </Tag>
            }
        </Flex>
    );
};

export default Clip;
