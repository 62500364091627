import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    bg: 'blackAlpha.100',
    color: 'title',
    fontWeight:'600',
    px:'12px'
  },
})

export const tagTheme = defineMultiStyleConfig({ baseStyle })