import { FormControl, FormLabel, Input, Flex, Textarea, Button, Center, Spinner, Alert, Box } from "@chakra-ui/react";
import { useState } from 'react';


export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isStatus, setStatus] = useState(0);
    const [isMessage, setMessage] = useState();


    const setMessages = (code) => {
        const ms = [
            "There was an error submitting the form. Please try again",
            "Your message has been submitted successfully."
        ]
        setIsSubmitting(false);
        setStatus(code)
        setMessage(ms[code - 1])
        setTimeout(() => {
            setStatus(0)
        }, 5000);
    }

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required.";
        } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
            newErrors.name = "Name can only contain letters and spaces.";
        }

        if (!formData.email.trim()) {
            newErrors.email = "Email is required.";
        } else if (
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
        ) {
            newErrors.email = "Enter a valid email address.";
        }

        if (!formData.subject.trim()) {
            newErrors.subject = "Subject is required.";
        } else if (formData.subject.length > 80) {
            newErrors.subject = "Subject cannot exceed 100 characters.";
        } else if (!/^[a-zA-Z0-9\s.,!?'-]+$/.test(formData.subject)) {
            newErrors.subject =
                "Subject contains invalid characters. Avoid special symbols.";
        }

        if (!formData.message.trim()) {
            newErrors.message = "Message is required.";
        } else if (formData.message.length > 500) {
            newErrors.message = "Message cannot exceed 500 characters.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', e.target.name.value);
        formData.append('email', e.target.email.value);
        formData.append('subject', e.target.subject.value);
        formData.append('message', e.target.message.value);

        if (validate()) {
            setIsSubmitting(true);

            fetch("././mail/contact_me.php", {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "success") {
                        setMessages(2);
                        setFormData({ name: "", email: "", subject: "", message: "" });
                    } else {
                        setMessages(1);
                    }
                })
                .catch((error) => {
                    setMessages(1);
                });
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Flex flexDirection={['column', 'column', 'row', 'row', 'row']} gap='6' mb='6'>
                    <FormControl variant="floating" id="name" isRequired>
                        <Input type="text" placeholder=" "
                            style={{ borderColor: errors.name ? "red" : "" }}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required />
                        <FormLabel>Name / Company</FormLabel>
                        {errors.name && <p className="errorText">{errors.name}</p>}
                    </FormControl>
                    <FormControl variant="floating" id="email" isRequired>
                        <Input type="email" placeholder=" "
                            style={{ borderColor: errors.email ? "red" : "" }}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required />
                        <FormLabel>Email</FormLabel>
                        {errors.email && <p className="errorText">{errors.email}</p>}
                    </FormControl>
                </Flex>
                <Flex gap='5' mb='6'>
                    <FormControl variant="floating" id="subject" isRequired>
                        <Input type="text" placeholder=" " maxLength="80"
                            style={{ borderColor: errors.subject ? "red" : "" }}
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required />
                        <FormLabel>Subject</FormLabel>
                        {errors.subject && <p className="errorText">{errors.subject}</p>}
                    </FormControl>
                </Flex>
                <FormControl variant="floating" id="message" isRequired mb='6'>
                    <Textarea name="message" placeholder=" " maxLength="400"
                        style={{ borderColor: errors.message ? "red" : "" }}
                        value={formData.message}
                        onChange={handleChange}
                        required resize='none' />
                    <FormLabel>Message</FormLabel>
                    {errors.message && <p className="errorText">{errors.message}</p>}
                </FormControl>
                <Center>
                    <Button type="submit" variant='ocean' size='lg'>
                        {isSubmitting ? (
                            <Spinner ml='2' size="sm" color="white" /> // Aquí pones tu spinner
                        ) : (
                            "Send"
                        )}
                    </Button>
                </Center>
            </form>
            <Box className={`snackbar ${isStatus !== 0 ? "active" : ""}`}>
                <Alert status={isStatus === 0 ? "info" : "" || isStatus === 1 ? "error" : "" || isStatus === 2 ? "success" : ""}>{isMessage}</Alert>
            </Box>
        </>
    )
};