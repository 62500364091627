import { Box, Container, Image, SimpleGrid, Heading, Divider, Text, Link } from '@chakra-ui/react'
import { IconLinkedIn } from '../styles/icons';

export default function Footer() {
    const year = new Date().getFullYear();
    return (
        <>
            <Box background='gradientOcean' pb='4' overflow='hidden'>
                <Box className="custom-shape-divider-top-1689299226" width={['300%', '200%', '100%', '100%']}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </Box>
                <Container maxW='container.xl' pt='4'>
                    <Box maxW='4xl'>
                        <Image src={require('../assets/logotype.png')} alt='Live Connect' />
                        <SimpleGrid my={['16', '24']} columns={[1, 2, 3]} spacingX='40px' spacingY='40px'>
                            <Box color='white'>
                                <Heading as='h4' size='md'>Contact</Heading>
                                <Divider borderColor='light' my='3' />
                                <Text>comercial@liveconnect.com.ar</Text>
                            </Box>
                            <Box color='white'>
                                <Heading as='h4' size='md'>Workplace</Heading>
                                <Divider borderColor='light' my='3' />
                                <Text>Buenos Aires, Argentina</Text>
                            </Box>
                            <Box color='white'>
                                <Heading as='h4' size='md'>Discover us</Heading>
                                <Divider borderColor='light' my='3' />
                                <Link target="_blank" href="https://www.linkedin.com/company/liveconnect-it">
                                    <IconLinkedIn height='24px' width='24px' color='white' />
                                </Link>

                            </Box>
                        </SimpleGrid>
                        <Text color='white' fontSize='sm'>
                            Live Connect S.A. © All rights reserved. 2009 - {year}
                        </Text>
                    </Box>
                </Container >
            </Box >
        </>
    )
}