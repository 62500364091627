import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    backgroundColor: 'white',
    borderRadius: '24px',
    padding: '48px',
    boxShadow: '0px 0px 60px 0px #DAEBFF',
  },
  header: {
    color: 'title',
    padding: '0px',
    paddingBottom: '16px',
  },
  body: {
    color: 'text',
    padding: '0px',
  },
  footer: {
    paddingTop: '2px',
  },
})

const variants = {
  funky: definePartsStyle({
    container: {
      padding: '0px',
      boxShadow: '0px 0px 0px 0px #DAEBFF',
    },
    header: {
      padding: '0px',
      overflow: 'hidden',
      borderRadius: '24px'
    },
    body: {
      color: 'text',
      padding: '40px',
      borderRadius: '24px',
      backgroundColor: 'white',
      marginTop: '-48px',
      boxShadow: '0px 0px 60px 0px #DAEBFF',
      h2: {
        color: 'title',
        paddingBottom: '12px',
      }
    },
    footer: {
      paddingTop: '2px',
    },
  }),
  ocean: definePartsStyle({
    container: {
      backgroundColor: 'none',
      boxShadow: '0px 0px 0px 0px #DAEBFF',
    },
    header: {
      overflow: 'hidden',
      borderRadius: '24px',
      color: 'dark',
      paddingBottom: '0px',
    },
    body: {
      color: 'primary',
      padding: '40px',
      borderRadius: '24px',
      padding:'0px'
    },
    footer: {
      paddingTop: '2px',
    },
  })
}

// const sizes = {
//   md: definePartsStyle({
//     container: {
//       borderRadius: '24px',
//     },
//   }),
// }

export const cardTheme = defineMultiStyleConfig({ baseStyle, variants })