import { Container, Flex, Heading, HStack, Image, Text, Button, Box } from '@chakra-ui/react'
import { useRef, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const activeStyle = (navData) => {
    return (navData.isActive ? 'active' : '');
}

const HomeIntro = () => {
    const main = useRef(null);
    const section = useRef(null);

    useLayoutEffect(() => {
        // Crear un contexto de GSAP MatchMedia
        const mm = gsap.matchMedia();

        mm.add("(min-width: 1080px)", () => {
            // Animaciones para la sección `main`
            const tl = gsap.timeline()
                .to(".intro-bg", { y: -200 }, 0)
                .to(".blob1", { y: -120 }, 0)
                .to(".blob3", { y: -60 }, 0);

            ScrollTrigger.create({
                trigger: main.current,
                animation: tl,
                scrub: 1,
                start: "top top",
                end: "bottom top",
            });

            // Animaciones para la sección `section`
            const ease = "power3.out";
            const duration = 1;

            const tl2 = gsap.timeline()
                .from(".intro .chakra-container", { x: -120, autoAlpha: 0 }, 0.2)
                .from(".intro h3", { x: -60, autoAlpha: 0 }, 0.2)
                .from(".intro img", { x: -60, autoAlpha: 0 }, 0.6)
                .from(".intro h2", { x: -60, autoAlpha: 0 }, 0.4)
                .from(".intro p", { x: -60, autoAlpha: 0 }, 0.5)
                .from(".intro-bg", { x: -400, duration: duration, ease: ease }, 0)
                .from(".blob1", { x: 1200, autoAlpha: 0, duration: duration, ease: ease }, 0.05)
                .from(".blob2", { x: 300, autoAlpha: 0, duration: duration, ease: ease }, 0.1)
                .from(".blob3", { x: 600, autoAlpha: 0, duration: duration, ease: ease }, 0.15);

            ScrollTrigger.create({
                trigger: section.current,
                animation: tl2,
                toggleActions: "play none none reverse",
                start: "top center",
                end: "bottom center",
            });
        });

        // Limpiar las configuraciones al desmontar
        return () => mm.revert();
    }, []);

    const nav = 88;
    const navResp = 56;

    return (
        <>
            <Box ref={main}>
                <Flex className='intro' ref={section}>
                    <Container maxW='container.xl'>
                        <Flex flexDirection='column' alignItems={['center', 'center', 'center', 'flex-start']} w='100%'>
                            <HStack spacing='4'>
                                <Heading color='white' as='h3' size='3xl' fontWeight='light'>We are</Heading>
                                <Image src={require('../../assets/isotype.png')} alt='Live Connect' />
                            </HStack>
                            <Heading color='white' mb='2' fontSize={['60px', '92px']}>Solutions</Heading>
                            <Text color='white' textStyle='platinum' maxW={['100%', 500]} mb='8' textAlign={['center', 'center', 'center', 'left']}>
                                We specialize in consultancy, with years of experience in delivering innovative <b>IT solutions</b>, helping businesses achieve their goals by optimizing processes, enhancing efficiency, and fostering growth.
                            </Text>

                            <NavLink to="/contact" className={activeStyle}>
                                <Button variant='accent' size='lg' display={['none', 'none', 'block']}>Connect</Button>
                            </NavLink>
                        </Flex>
                    </Container>
                    <Box className='intro-bg' />
                    <Box className='blob blob1' />
                    <Box className='blob blob2' />
                    <Box className='blob blob3' />
                </Flex>
            </Box>
        </>
    )
};

export default HomeIntro;