import { Container, Box, Heading, Text, Center, SimpleGrid } from '@chakra-ui/react'
import ContactForm from '../components/ContactForm';
import Intro from '../components/Intro';
import { useEffect } from 'react';
import Clip from "../components/Clip";


const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const data = { title: 'Connect with us', bg: 'gradientOceanWave' }
    return (
        <>
            <Intro section={data} />
            <Box py={[16, 16, 16, '120px']} background='background'>
                <Container maxW='container.xl'>
                    <Box flexDirection='column' mb='48px'>
                        <Heading color='primary' size='xl' as='h3' mb='4'>Let’s connect!</Heading>
                        <Text color='text' textStyle='platinum'>
                            If you need help with a project or a business request,
                            <br />
                            please contact us.
                        </Text>
                    </Box>
                    <SimpleGrid columns={[1, 1, 3, 3]} gap={[16, 16, 16, 32]}>
                        <Box gridColumn={['1 / span 1', '1 / span 1', '1 / span 2', '1 / span 2']}>
                            <ContactForm />
                        </Box>
                        <Box flexDirection='column' mb='32px' gridColumn={['1 / span 1', '1 / span 1', '3 / span 1', '3 / span 1']}>
                            <Box color='text' textStyle='platinum' textAlign='center'>
                                <Heading color='title' size='md' as='h4' mb='1'>Or you can email us to</Heading>
                                <Clip text="comercial@liveconnect.com.ar" />
                            </Box>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    )
};

export default Contact;