import { Container, SimpleGrid, Heading, Text, Center, Image, Box } from '@chakra-ui/react'
import { useRef, useLayoutEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Tech = () => {

    const main = useRef(null);
    useLayoutEffect(() => {
        const mm = gsap.matchMedia();

        mm.add("(min-width: 1080px)", () => {
            const tl = gsap.timeline()
                .from(".tech h2", { y: 120, autoAlpha: 0 }, 0)
                .from(".tech p", { y: 150, autoAlpha: 0 }, 0)
                .from(".tech .css-gmuwbf", { y: 120, autoAlpha: 0, stagger: 0.1 }, 0)
            ScrollTrigger.create({
                trigger: main.current,
                animation: tl,
                toggleActions: "play none none reverse",
                start: "top center",
                end: "bottom bottom",
            })
        }, main);
        return () => mm.revert();
    }, []);

    const TechInfo = [
        { title: 'SAP' },
        { title: 'Java' },
        { title: 'NET' },
        { title: 'Python' },
        { title: 'SQL' },
        { title: 'Javascript' },
        { title: 'React' },
        { title: 'Angular' },
    ]

    return (
        <>
            <Box className='tech' background='white' ref={main} pt={[16, 16, 16, '160px']} pb={[16, 16, 16, '180px']} borderBottomRightRadius={[0, 0, 0, '200px']}>
                <Container position='relative' maxW='container.xl'>
                    <Box className='panel' display={['none', 'none', 'none', 'block', 'block']}>
                        <Box className='dot' background='light' />
                        <Box className='dot' border='4px solid' borderColor='primary' />
                        <Box className='dot' background='lighten' />
                    </Box>
                    <Box className='keys' display={['none', 'none', 'none', 'block', 'block']}>
                        <Text color='light' fontSize='10em' fontWeight='700'>&#123; &#125;</Text>
                    </Box>
                    <Center flexDirection='column' mb='32px'>
                        <Heading as='h2' color='title' size='2xl' mb='4'>Technology</Heading>
                        <Text textAlign='center' color='text' maxW={[300]} mb='8' textStyle='platinum'>
                            Over +20 technologies working nowadays specializing in diverse areas
                        </Text>
                    </Center>
                    <SimpleGrid spacing={6} columns={[4, 6, 8]}>
                        {TechInfo.map((item, i) => (
                            <Center key={i}>
                                <Image src={require('../../assets/tech/' + item.title + '.png')} alt={item.title} />
                            </Center>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    )
};

export default Tech;