import { Container, Center, Box, Heading, Text, } from '@chakra-ui/react'

const Intro = (props) => {

    return (
        <>
            <Box className='intro-section' background={props.section.bg ? props.section.bg : 'gradientDarkWave'} borderBottomLeftRadius={[0, 0, '40%']} borderBottomRightRadius={[0, 0, '40%']}>
                <Container maxW='container.xl'>
                    <Center flexDirection='column'>
                        <Heading as='h2' size='3xl' color='white' mb='2'>{props.section.title}</Heading>
                        {/* <Text color='white' textAlign='center' fontSize='xl' maxW={[200, 300, 500]}>
                            {props.section.text}
                        </Text> */}
                    </Center>
                </Container>
            </Box>
        </>
    )
};

export default Intro;