import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const variants = {
    carousel: definePartsStyle({
        tab: {
            paddingX:'6',
            paddingY:'3',
            justifyContent:'flex-start',
            color:'title',
            fontWeight:'600',
            borderRadius: '16',
            _hover:{
                background:'blackAlpha.50'
            },
            _selected: {
                color:'dark',
                background:'lighter',
              },
          },
          tabpanel: {
            
          },
    })
  }

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants })