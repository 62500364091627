import { Button, Flex, Container, Image, Box } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom';
import { IconSignal } from '../styles/icons';
import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function Navbar() {

    const main = useRef(null);
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            ScrollTrigger.create({
                trigger: main.current,
                start: "60px top",
                pinSpacing: false,
                // pin: main.current,
                end: 99999,
                toggleClass: { className: 'nav--scrolled', targets: '.nav' }
            })
        }, main);

        return () => ctx.revert();
    }, []);

    const activeStyle = (navData) => {
        return (navData.isActive ? 'active' : '');
    }

    return (
        <>
            <Box className='nav' ref={main}>
                <Container maxW='container.xl'>
                    <Flex direction='row' justify='space-between' align='center'>
                        <NavLink to="/">
                            <Image src={require('../assets/logotype.png')} alt='Live Connect' />
                            {/* <Image width='40px' display={['block', 'none']} src={require('../assets/favicon.png')} alt='Live Connect' /> */}
                        </NavLink>
                        <Flex direction='row' justify='center' align='center' gap='1'>
                            {/* <NavLink to="/about" className={activeStyle}>
                                <Button variant='invisible'>About</Button>
                            </NavLink>
                            <NavLink to="/services" className={activeStyle}>
                                <Button variant='invisible'>Services</Button>
                            </NavLink> */}
                            <NavLink to="/contact" className={activeStyle}>
                                <Box ml='2' position='relative'>
                                    <Button variant='animate' className="btn-anim" px={[6, 8]}>Connect</Button>
                                    <Box className="btn-signal" >
                                        <IconSignal height='20px' width='20px' color='accent' />
                                        <IconSignal height='24px' width='24px' color='accent' />
                                    </Box>
                                </Box>
                            </NavLink>
                        </Flex>
                    </Flex>
                </Container>
            </Box>
        </>
    )
}