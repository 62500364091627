
import { Container, SimpleGrid, Heading, Text, Image, Card, CardBody, CardHeader, Box } from '@chakra-ui/react'
import { IconTeam, IconFlag, IconGrowth } from '../../styles/icons';
import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DataAbout from '../../data/DataAbout.json';

const About = () => {

    const main = useRef(null);

    useLayoutEffect(() => {
        // Crear un contexto de GSAP MatchMedia
        const mm = gsap.matchMedia();

        mm.add("(min-width: 1080px)", () => {
            const tl = gsap.timeline()
                .from(".HomeAbout h2", { y: 120, autoAlpha: 0 }, 0)
                .from(".HomeAbout .chakra-card", { y: 120, autoAlpha: 0, stagger: 0.2 }, 0);

            ScrollTrigger.create({
                trigger: main.current,
                animation: tl,
                toggleActions: "play none none reverse",
                start: "top 60%",
                end: "bottom bottom",
            });
        });

        // Limpiar las configuraciones al desmontar
        return () => mm.revert();
    }, []);

    return (
        <>
            <Box className='HomeAbout' ref={main} mt={[4, 16, 16, 16]}>
                <Container maxW='container.xl' pb={[16, 16, 16, 200]}>
                    <Heading as='h2' size='2xl' mb='60px' color='primary' textAlign='center'>About us</Heading>
                    <SimpleGrid spacing={6} columns={[1, 1, 2, 3, 3]}>
                        {DataAbout.map((item, i) => (
                            <Card key={i} data-theme='light' padding={['24px', '24px', '32px', '32px', '48px']}>
                                <CardHeader>
                                    <Box height='40px'>
                                        {i == 0 &&
                                            <IconTeam mb='12px' height='26px' width='40px' color='primary' />
                                        }
                                        {i == 1 &&
                                            <IconFlag mb='12px' height='26px' width='40px' color='primary' />
                                        }
                                        {i == 2 &&
                                            <IconGrowth mb='12px' height='26px' width='40px' color='primary' />
                                        }
                                    </Box>
                                    <Heading as='h4' size='md'>{item.title}</Heading>
                                </CardHeader>
                                <CardBody>
                                    <Text>{item.text}</Text>
                                </CardBody>
                            </Card>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    )
};

export default About;